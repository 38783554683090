.ng-resizable {
    /*top: 30px;*/
    /*left: 150px;*/
    /*width: 150px;*/
    /*height: 150px;*/
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: black;
    /*font-family: Verdana;*/
    text-shadow: 1px 1px 0 #bbb;
    border: 1px solid #ccc;
    overflow: hidden;
}

.ng-resizable.active {
    z-index: 2 !important;
    background-color: rgba(157, 189, 82, 0.7);
}

.ng-resizable__content {
    margin: 5px;
    overflow: hidden;
    cursor: move;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    display: flex;
    /*align-items: center;*/
    justify-content: center;
}


.dots {
    background-color: rgba(255, 255, 255, 0.5);
    overflow: visible;
    z-index: 99;
}
.dots .ngr-grabber.ngr-bottom-left,
.dots .ngr-grabber.ngr-bottom-right,
.dots .ngr-grabber.ngr-top-left,
.dots .ngr-grabber.ngr-top-right {
    width: 10px !important;
    height: 10px !important;
    border-radius: 50% !important;
    color: rgb(0, 170, 255) !important;
    background-color: rgb(0, 170, 255) !important;
}


.ngr-grabber.ngr-top:before,
.ngr-grabber.ngr-bottom:before,
.ngr-grabber.ngr-right:before,
.ngr-grabber.ngr-left:before {
    background-color: rgb(0, 150, 255) !important;
}

.tag__trash {
    position: absolute;
    z-index: 100;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.6);
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
}

.tag__trash i {
    color: red;
    font-size: 1.5em;
    animation: shake 0.1s ease-in-out 0.1s infinite alternate;
    z-index: 100;
}

@keyframes shake {
    from {
        transform: rotate(10deg);
    }
    to {
        transform-origin: center center;
        transform: rotate(-10deg);
    }
}

