/****** VARs ******/

/****** BiziBAZA Colours **********/

//(Main active UI elements)
$bizibazagreen:     #9EBB4F;
//(Secondary UI elements, secondary text)
$bizibazagray:      #666666;
//(General text)
$bizibaza_general:  #000000;
//(Not active UI elements, hints text)
$bizibaza_noactive: #B9B9B9;

//Aditional:
$bizibaza_orange:   #FF9308;
$bizibaza_red:      #E32F16;
$bizibaza_blue:     #4B80A0;
$bizibaza_lightblue: #2ea7fd;

//////////////////////////////////////


$grey:              #777;
$darkgrey:          #333;
$gainsboro:         #dddddd;
$lightgrey:         #eee;
$lightergray:       #f3f3f3;
$altgreydark:       #413b36;
$altgrey:           #d4e2db;
$disabledgray:       #b3b3b3;
$dimgrey:           rgb(102,102,102);
//$airgrey:           rgb(170,170,170)
$airgrey:           #a7a4a4;
$lightgreyBorder:   #ddd;
$mineShaft:         #222222;
$verylightgrey:     #cccccc;
$Gray52:            #858585;
$airGreyBorder:     rgb(201,199,203);
$snowDrift:         rgb(243,246,244);
$zircon:            rgb(253,254,255);
$lightTheme:        rgb(255,255,255);
$darkgrey2:         rgb(170,170,170);
$purple:            #6c5b9a;
$vistaWhite:        #dddcda;
$aliceBlue:         #f2f6f5;
$greyText:          rgb(129,129,129);

$carousel_bg:       whitesmoke;

$darkgreentea:      rgb(142,195,79);
$lightgreen:        rgb(187,221,149);
$greentea:          #edf6e4; // rgba(207, 231, 181, 0.38)
$celery:            #9DBD52;
$mantis:            #85BE52;
$berylGreen:        #D9E5BF;

$yellow:            #dfd524;
$webOrange:         #ffa300;
$amber:             #FFC400;
$inactive:          #f3eeea;

$asideBGColor:      white;
$gallery:           #F0F0F0;

$popupOKBG:         #b6e39b;
$popupWarningBG:    #fffeaf;
$popupErrorBG:      #ff836d;
$warning:           #f5961d;
$darkOrange:        #ff8700;
$dirtyOrange:       #d2691ebd;
$safetyOrange:      #f3000e;

$organic:           #ecfbda;
$lightrose:         #fff3f2;

$buy:   #AFE39B;
$cart:  #b6ebff;
$watch: #fffeaf;
$fav: #ffdfdf;

$impersonation-message-block-height: 25px;

@mixin linear-gradient__to-bottom($from, $to) {
  background: $to;
  background: linear-gradient(to bottom, $from 0%,$to 100%); }

@mixin linear-gradient__to-top($from, $to) {
  background: $to;
  background: linear-gradient(to top, $from 0%,$to 100%); }

@mixin border__transparent-leftright($from) {
    border-image-source: linear-gradient(0deg, transparent, $from, transparent);
    border-image-slice: 1;
    border-image-repeat: stretch;
    border-width: 1px;
    border-style: solid;
    border-top: 0;
    border-bottom: 0; }

@mixin border__bottom($color) {
    border-bottom: ($color);
    border-width: 1px;
    border-style: solid;
    border-left: 0;
    border-right: 0;
    border-top: 0; }

@mixin border__top($color) {
    border-top: ($color);
    border-width: 1px;
    border-style: solid;
    border-left: 0;
    border-right: 0;
    border-bottom: 0; }

@mixin border__topbottom($color) {
    border-top: ($color);
    border-bottom: ($color);
    border-width: 1px;
    border-style: solid;
    border-left: 0;
    border-right: 0; }

@mixin border__leftright($color) {
    border-left: ($color);
    border-right: ($color);
    border-width: 1px;
    border-style: solid;
    border-top: 0;
    border-bottom: 0; }

@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0); }

  to {
    opacity: 1;
    transform: none; } }


.fadeInLeftBig {
  animation-name: fadeInLeftBig; }

@keyframes fadeInRightBig {
  from {
    opacity: 1;
    transform: none; }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0); } }


.fadeInRightBig {
  animation-name: fadeInRightBig; }

.list__border-grey {
    border-bottom: 1px solid $vistaWhite;
    padding: 0 10px; }
/********************************/

/***** MIXINS *****/
@mixin placeholder($color, $size:"") {
    &::-webkit-input-placeholder {
        font-family: 'Avenir', sans-serif;
        color: $color;
        @if $size != "" {
            font-size: $size; } }

    &:-moz-placeholder {
        font-family: 'Avenir', sans-serif;
        color: $color;
        @if $size != "" {
            font-size: $size; } }

    &::-moz-placeholder {
        font-family: 'Avenir', sans-serif;
        color: $color;
        @if $size != "" {
            font-size: $size; } }

    &:-ms-input-placeholder {
        font-family: 'Avenir', sans-serif;
        color: $color;
        @if $size != "" {
            font-size: $size; } } }
/********************************/

/* Hide default HTML checkbox and create New Checkbox */
.onoffswitch {
    position: relative;
    width: 60px;
    -ms-user-select: none; }

.onoffswitch-checkbox {
    display: none; }

.onoffswitch-label {
    display: block;
    height: 27px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border-radius: 5px; }

.onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s; }

.onoffswitch-inner:before, .onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 100%;
    padding: 0;
    line-height: 29px;
    font-size: 14px;
    color: white;
    font-family: 'Avenir', sans-serif;
    box-sizing: border-box;
    background-color: $darkgreentea; }

.onoffswitch-inner:before {
    content: "ON";
    padding-left: 2%;
    text-align: left; }

.onoffswitch-inner:after {
    content: "OFF";
    padding-right: 2%;
    text-align: right; }

.onoffswitch-switch {
    display: block;
    width: 39%;
    height: 83%;
    margin: 0.4px;
    background: #FFFFFF;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 32px;
    border: 2px solid $darkgreentea;
    border-radius: 5px;
    transition: all 0.3s ease-in 0s; }

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0; }

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px; }
/********************************/

/*******  Radio button **********/
ul.radiobtn--block {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: auto;
    & li {
        color: #AAAAAA;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 10px 0;
        word-break: break-all; }
    & input[type=radio] {
        position: absolute;
        visibility: hidden; }
    & label {
        padding-left: 20px;
        margin: auto 0;
        cursor: pointer;
        transition: all 0.25s linear; }
    &  li .check {
        border: 1px solid $verylightgrey;
        border-radius: 100%;
        height: 24px;
        width: 24px;
        top: 0;
        left: 0;
        z-index: 5;
        transition: border .25s linear; }
    & li .check::before {
        display: block;
        content: '';
        border-radius: 100%;
        height: 14px;
        width: 14px;
        margin: 5px auto;
        transition: background 0.25s linear; }
    & input[type=radio]:checked ~ .check {
        border: 1px solid $mantis; }
    & input[type=radio]:checked ~ .check::before {
        background: $mantis; }
    & input[type=radio]:checked ~ label {
        color: black; } }
/********************************/

/******* RATING STARS **********/
fieldset {
    border: none;
    padding: 0; }

.rating:not(:checked) {
    > input {
        position: absolute;
        top: -9999px;
        clip: rect(0,0,0,0); }
    > label {
        padding: 0.1em;
        overflow: hidden;
        white-space: nowrap;
        font-size: 100%;
        line-height: 1.2;
        color: $lightgrey; } }

.rating:not(:checked) {
    > label:before {
        content: '★ '; } }

.rating {
    //unicode-bidi: bidi-override
    //direction: rtl
    > input:checked ~ label {
        color: $amber; } }

//.rating:not(:checked)
//    > label:hover, &~ label
//        color: gold

//.rating > input:checked ~ label:hover ~ label,
//.rating > label:hover ~ input:checked ~ label
//    color: #f70
//.rating > span:hover:before
//   content: "\2605"
//   position: absolute
//
//.rating > span
//    display: inline-block
//    position: relative
//    width: 1.1em
//    color: $airGreyBorder
//
//.rating > span:hover:before,
//.rating > span:hover ~ span:before
//    content: "\2605"
//    position: absolute
//    color: $amber

/********************************/

.counter-block {
    width: 25px;
    height: 25px;
    background-color: rgb(129, 170, 33);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: white; }

.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
