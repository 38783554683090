@font-face {
    font-family: Avenir;
    src: url(../src/assets/fonts/AvenirLTStd-Light.otf) format("opentype"),
         url(../src/assets/fonts/AvenirLTStd-Light.woff) format('woff'),
         url(../src/assets/fonts/Avenir-LT.ttf) format('ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: Avenir;
    src: url(../src/assets/fonts/Avenir-Heavy.ttf) format('ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: SourceSansPro;
    src: url('../src/assets/fonts/Source_Sans_Pro/SourceSansPro-ExtraLight.ttf') format("ttf");
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: SourceSansPro;
    src: url('../src/assets/fonts/Source_Sans_Pro/SourceSansPro-Light.ttf') format("ttf");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: SourceSansPro;
    src: url('../src/assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf') format("ttf");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: SourceSansPro;
    src: url('../src/assets/fonts/Source_Sans_Pro/SourceSansPro-SemiBold.ttf') format("ttf");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: SourceSansPro;
    src: url('../src/assets/fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf') format("ttf");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: SourceSansPro;
    src: url('../src/assets/fonts/Source_Sans_Pro/SourceSansPro-Black.ttf') format("ttf");
    font-weight: 900;
    font-style: normal;
}


@font-face {
  font-family: 'fontello';
  src: url('../src/assets/fonts/fontello/fontello.eot');
  src: url('../src/assets/fonts/fontello/fontello.eot') format('embedded-opentype'),
       url('../src/assets/fonts/fontello/fontello.woff2') format('woff2'),
       url('../src/assets/fonts/fontello/fontello.woff') format('woff'),
       url('../src/assets/fonts/fontello/fontello.ttf') format('truetype'),
       url('../src/assets/fonts/fontello/fontello.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@import "../src/assets/styles/fontello/fontello.css";
@import "../src/assets/styles/fontello/fontello-codes.css";
@import "../src/assets/styles/fontello/fontello-embedded.css";
@import "../src/assets/styles/fontello/fontello-ie7-codes.css";
@import "../src/assets/styles/fontello/fontello-ie7.css";

[class^="icon-"]:hover, [class*=" icon-"]:hover {
    transition: 0.5s;
}

.icon-trash-empty:hover {
    color: red;
}
/*@import url(//fonts.googleapis.com/css?family=Open+Sans);*/
