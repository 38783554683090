.sprite-map_callout_arrow_2x {
    zoom: 0.7;
    -moz-transform: scale(0.7); }

.sprite-map_callout_back-arrow_2x {
    transform: rotate(180deg);
    @extend .sprite-map_callout_arrow_2x; }

//
//
//
//

.sprite-locate {
    width: 30px;
    height: 47px;
    background-position: 0 0; }

.sprite-accessory_tab_bar_2x {
    width: 70px;
    height: 20px;
    background-position: -45px -5px; }

.sprite-add_icon_2x {
    width: 32px;
    height: 32px;
    background-position: -121px 0px; }

.sprite-add_image_2x {
    width: 172px;
    height: 120px;
    background-position: -167px -5px; }

.sprite-add_image_icon_2x {
    width: 133px;
    height: 85px;
    background-position: -349px -5px; }

.sprite-add_to_sl {
    width: 65px;
    height: 65px;
    margin-right: 5px;
    background-position: -491px 0px; }

.sprite-address_icon_user_details_3x {
    width: 61px;
    height: 88px;
    background-position: -565px 0px; }

.sprite-orders_icon_2x {
    width: 60px;
    height: 58px;
    background-position: -631px 0px; }

.sprite-apple {
    width: 67px;
    height: 72px;
    background-position: -5px -73px; }

.sprite-ar_review_icon {
    width: 20px;
    height: 34px;
    background-position: -700px -5px; }

.sprite-arr_icon_2x {
    width: 22px;
    height: 32px;
    background-position: -80px -45px; }

.sprite-arrow_up_home_menu_2x {
    width: 44px;
    height: 44px;
    background-position: -633px -68px; }

.sprite-avaible_2x {
    width: 28px;
    height: 35px;
    background-position: -110px -69px; }

.sprite-backIcon_2x {
    width: 27px;
    height: 44px;
    background-position: -686px -73px; }

.sprite-bag_icon_2x {
    width: 32px;
    height: 38px;
    background-position: -82px -127px; }

.sprite-bag_selected_icon_2x {
    width: 32px;
    height: 38px;
    background-position: -124px -127px; }

.sprite-bakery {
    width: 95px;
    height: 74px;
    background-position: -349px -103px; }

.sprite-bizi_baza_logo_2x {
    width: 315px;
    height: 76px;
    background-position: 9px -182px; }

.sprite-bizioix_icon {
    width: 89px;
    height: 77px;
    background-position: -454px -127px; }

.sprite-buyer_tab_icon_2x {
    width: 57px;
    height: 65px;
    background-position: -553px -123px; }

.sprite-calendar_icon_2x {
    width: 56px;
    height: 60px;
    background-position: -620px -127px; }

.sprite-camers_shadow_2x {
    width: 52px;
    height: 46px;
    background-position: -334px -192px; }

.sprite-car_icon_2x {
    width: 60px;
    height: 34px;
    background-position: -620px -197px; }

.sprite-car_selected_icon_2x {
    width: 60px;
    height: 35px;
    background-position: -396px -241px; }

.sprite-cart_icon_2x {
    width: 56px;
    height: 50px;
    background-position: -468px -238px; }

.sprite-certificate_icon_2x {
    width: 46px;
    height: 54px;
    background-position: -532px -241px; }

.sprite-check_shadow_2x {
    width: 44px;
    height: 36px;
    background-position: -588px -241px; }

.sprite-checkmark_2x {
    width: 50px;
    height: 40px;
    background-position: -642px -241px; }

.sprite-checkmark_successful_payment_icon_2x {
    width: 60px;
    height: 56px;
    background-position: -5px -291px; }

.sprite-clock_icon_2x {
    width: 40px;
    height: 42px;
    background-position: -75px -291px; }

.sprite-clock_icon_shopping_cart_2x {
    width: 25px;
    height: 25px;
    background-position: -686px -121px; }

.sprite-close {
    width: 54px;
    height: 54px;
    background-position: -125px -291px; }

.sprite-close_icon {
    width: 20px;
    height: 20px;
    background-position: -686px -159px; }

.sprite-close_icon_2x {
    width: 40px;
    height: 40px;
    background-position: -189px -291px; }

.sprite-close_shadow_2x {
    width: 36px;
    height: 36px;
    background-position: -395px -184px; }

.sprite-closed_section_arrow_icon_2x {
    width: 28px;
    height: 16px;
    background-position: -5px -159px; }

.sprite-credit_icon_2x {
    width: 44px;
    height: 31px;
    background-position: -239px -291px; }

.sprite-custom_back_icon_2x {
    width: 28px;
    height: 42px;
    background-position: -690px -189px; }

.sprite-dairy {
    width: 32px;
    height: 72px;
    background-position: -293px -291px; }

.sprite-description_3x {
    width: 49px;
    height: 49px;
    background-position: -332px -288px; }

.sprite-disclosure_indicator_icon_2x {
    width: 24px;
    height: 36px;
    background-position: -393px -291px; }

.sprite-dislike_review_icon_2x {
    width: 47px;
    height: 47px;
    background-position: -587px -286px; }

.sprite-draft_icon {
    width: 60px;
    height: 60px;
    background-position: -642px -291px; }

.sprite-e_2x {
    width: 70px;
    height: 70px;
    background-position: 0px -359px; }

.sprite-equal_sign_2x {
    width: 20px;
    height: 10px;
    background-position: -45px -49px; }

.sprite-error_input_icon_2x {
    width: 22px;
    height: 22px;
    background-position: -427px -291px; }

.sprite-event_2x {
    width: 32px;
    height: 30px;
    background-position: -85px -361px; }

.sprite-expired_2x {
    width: 62px;
    height: 62px;
    background-position: -122px -357px; }

.sprite-fav_icon_disabled_2x {
    width: 41px;
    height: 37px;
    background-position: -194px -357px; }

.sprite-filter_icon_2x {
    width: 88px;
    height: 76px;
    background-position: -335px -361px; }

.sprite-filter_selected_icon_2x {
    width: 44px;
    height: 44px;
    background-position: -433px -361px; }

.sprite-home_2x {
    width: 43px;
    height: 39px;
    background-position: -487px -361px; }

.sprite-info {
    width: 70px;
    height: 70px;
    background-position: -540px -361px; }

.sprite-like_review_icon_2x {
    width: 47px;
    height: 47px;
    background-position: -620px -357px; }

.sprite-lk_icon_2x {
    width: 40px;
    height: 36px;
    background-position: -674px -361px; }

.sprite-lk_selected_icon_2x {
    width: 42px;
    height: 37px;
    background-position: -193px -405px; }

.sprite-maestro_icon_2x {
    width: 50px;
    height: 30px;
    background-position: -247px -407px; }

.sprite-mail_icon_user_details_2x {
    width: 46px;
    height: 46px;
    background-position: -674px -404px; }

.sprite-map_callout_arrow_2x {
    width: 25px;
    height: 35px;
    background-position: -245px -358px; }

.sprite-map_icon_2x {
    width: 90px;
    height: 92px;
    background-position: -433px -415px; }

.sprite-map_pin_market_icon {
    width: 22px;
    height: 32px;
    background-position: -85px -407px; }

.sprite-map_pin_market_icon_2x {
    width: 44px;
    height: 63px;
    background-position: -5px -461px; }

.sprite-map_pin_market_icon_3x {
    width: 66px;
    height: 95px;
    background-position: -59px -461px; }

.sprite-map_pin_user_icon {
    width: 22px;
    height: 32px;
    background-position: -135px -461px; }

.sprite-map_pin_user_icon_2x {
    width: 44px;
    height: 63px;
    background-position: -167px -461px; }

.sprite-map_pin_user_icon_3x {
    width: 66px;
    height: 95px;
    background-position: -221px -461px; }

.sprite-master_card_icon_2x {
    width: 50px;
    height: 30px;
    background-position: -297px -461px; }

.sprite-meat {
    width: 89px;
    height: 72px;
    background-position: -533px -461px; }

.sprite-message_2x {
    width: 46px;
    height: 34px;
    background-position: -355px -459px; }

.sprite-ms_icon_2x {
    width: 44px;
    height: 32px;
    background-position: -632px -461px; }

.sprite-multiply_sign_2x {
    width: 16px;
    height: 16px;
    background-position: -43px -159px; }

.sprite-negative_icon_2x {
    width: 85px;
    height: 125px;
    background-position: -297px -503px; }

.sprite-new_item_icon_2x {
    width: 58px;
    height: 58px;
    background-position: -632px -500px; }

.sprite-no_image_icon {
    zoom: 0.47;
    -moz-transform: scale(0.47);
    width: 128px;
    height: 128px;
    background-position: -392px -543px; }

.sprite-no_user_photo_icon_2x {
    width: 68px;
    height: 68px;
    background-position: -2px -567px; }

.sprite-not_selected_stars_2x {
    width: 88px;
    height: 76px;
    background-position: -83px -567px; }

.sprite-opened_section_arrow_icon_2x {
    width: 28px;
    height: 16px;
    background-position: -166px -159px; }

.sprite-other {
    width: 65px;
    height: 73px;
    background-position: -181px -567px; }

.sprite-pay_icon_2x {
    width: 113px;
    height: 44px;
    background-position: -530px -567px; }

.sprite-pay_icon_ok {
    width: 114px;
    height: 45px;
    background-position: -529px -620px; }

.sprite-paypal_singup_2x {
    width: 88px;
    height: 24px;
    background-position: 2px -675px; }

.sprite-paypall_2x {
    width: 36px;
    height: 42px;
    background-position: -653px -567px; }

.sprite-pencil_shadow_2x {
    width: 42px;
    height: 44px;
    background-position: -653px -619px; }

.sprite-phone_icon_user_details_2x {
    width: 39px;
    height: 41px;
    background-position: -95px -673px; }

.sprite-photo_icon_2x {
    width: 40px;
    height: 40px;
    background-position: -148px -673px; }

.sprite-positive_icon_2x {
    width: 85px;
    height: 125px;
    background-position: -730px 0px; }

.sprite-poultry {
    width: 73px;
    height: 73px;
    background-position: -728px -140px; }

.sprite-price_alert_2x {
    width: 36px;
    height: 40px;
    background-position: -728px -223px; }

.sprite-product-code_2x {
    width: 38px;
    height: 34px;
    background-position: -202px -136px; }

.sprite-profile_icon_2x {
    width: 64px;
    height: 64px;
    background-position: -714px -271px; }

.sprite-radio_button_deselected_icon_2x {
    width: 44px;
    height: 44px;
    background-position: -724px -347px; }

.sprite-radio_button_selected_icon_2x {
    width: 44px;
    height: 44px;
    background-position: -728px -401px; }

.sprite-reviews_icon {
    width: 44px;
    height: 40px;
    background-position: -777px -220px; }

.sprite-right_arrow_2x {
    width: 20px;
    height: 35px;
    background-position: -252px -140px; }

.sprite-sale-price_2x {
    width: 34px;
    height: 36px;
    background-position: -280px -136px; }

.sprite-sale_icon_2x {
    width: 88px;
    height: 88px;
    background-position: -730px -455px; }

.sprite-sold_out_icon_2x {
    width: 88px;
    height: 88px;
    background-position: -4px -867px; }

.sprite-scales_icon_2x {
    width: 66px;
    height: 48px;
    background-position: -699px -553px; }

.sprite-seafood {
    width: 83px;
    height: 72px;
    background-position: -705px -611px; }

.sprite-search_icon_2x {
    width: 81px;
    height: 84px;
    background-position: -193px -693px; }

.sprite-selected_stars_2x {
    width: 88px;
    height: 76px;
    background-position: -280px -693px; }

.sprite-seller_tab_icon_2x {
    width: 58px;
    height: 64px;
    background-position: -377px -693px; }

.sprite-selling-price_2x {
    width: 21px;
    height: 40px;
    background-position: -789px -270px; }

.sprite-settings_icon_2x {
    width: 84px;
    height: 84px;
    background-position: -444px -691px; }

.sprite-shareIcon_2x {
    width: 45px;
    height: 53px;
    background-position: -536px -693px; }

.sprite-shopix_2x {
    width: 102px;
    height: 77px;
    background-position: -589px -692px; }

.sprite-shopper_icon {
    width: 16px;
    height: 16px;
    background-position: -427px -323px; }

.sprite-shopping_cart_pencil_icon_2x {
    width: 40px;
    height: 40px;
    background-position: -778px -347px; }

.sprite-show_password_disabled_2x {
    width: 40px;
    height: 26px;
    background-position: -777px -552px; }

.sprite-show_password_enabled_2x {
    width: 40px;
    height: 26px;
    background-position: -691px -693px; }

.sprite-signup_fail_icon_2x {
    width: 25px;
    height: 25px;
    background-position: -453px -323px; }

.sprite-sort_checkmark_icon_2x {
    width: 32px;
    height: 24px;
    background-position: -488px -323px; }

.sprite-sort_icon_2x {
    width: 44px;
    height: 44px;
    background-position: -738px -693px; }

.sprite-success_icon_2x {
    width: 32px;
    height: 24px;
    background-position: -530px -323px; }

.sprite-tag_shadow_2x {
    width: 42px;
    height: 42px;
    background-position: -5px -747px; }

.sprite-to_favorites_2x {
    width: 58px;
    height: 50px;
    background-position: -52px -746px; }

.sprite-total_price_pay_form_2x {
    width: 36px;
    height: 44px;
    background-position: -782px -397px; }

.sprite-trash_shadow_2x {
    width: 40px;
    height: 44px;
    background-position: -123px -747px; }

.sprite-units_2x {
    width: 33px;
    height: 32px;
    background-position: -691px -747px; }

.sprite-vegetables {
    width: 73px;
    height: 72px;
    background-position: -732px -747px; }

.sprite-visa_icon_2x {
    width: 58px;
    height: 18px;
    background-position: -5px -829px; }

.sprite-webpage_icon_user_details_2x {
    width: 46px;
    height: 46px;
    background-position: -827px 0px; }

.sprite-wt-list_icon_2x {
    width: 52px;
    height: 39px;
    background-position: -811px -134px; }

.sprite-wt_icon_2x {
    width: 50px;
    height: 34px;
    background-position: -817px -273px; }

.sprite-wt_selected_icon_2x {
    width: 52px;
    height: 39px;
    background-position: -800px -585px; }

.sprite-sheet_icon {
    width: 70px;
    height: 71px;
    background-position: -509px -780px; }

.sprite-location_address_icon {
    width: 66px;
    height: 61px;
    background-position: -595px -781px; }

.sprite__save-icon {
    zoom: 0.6;
    width: 45px;
    height: 45px;
    background-position: -283px -804px; }

