// icons font styles
.icon-search_icon {
    color: $bizibazagreen;
    font-size: 25px; }

.icon-buyer_tab_icon,
.icon-seller_tab_icon {
    font-size: 45px; }

.icon-eye_outlined {
    color: $bizibaza_noactive; }

.icon-eye_outlined,
.icon-watchlist_icon {
    font-size: 18px;

    #app-footer-menu & {
        font-size: 24px;
        height: 24px;
        color: $altgreydark; } }

.icon-shopix {
    font-size: 46px; }

.icon-favorites_filled,
.icon-favorites_outlined {
    font-size: 30px;
    height: 30px;
    vertical-align: top;
    color: $bizibaza_noactive;

    .popup-nav__item & {
        color: $safetyOrange; } }

.icon-favorites_outlined {
    color: $bizibaza_noactive; }

.icon-favorites_filled {
    color: $safetyOrange; }

.icon-certificate_icon {
    font-size: 27px;
    color: $bizibazagreen; }

.icon-disclosure_indicator_icon {
    font-size: 22px; }

.icon-custom_back_icon {
    color: $bizibazagreen;
    font-size: 24px;
    height: 24px;
    vertical-align: top; }

.icon-map_icon {
    color: $bizibazagreen;
    font-size: 25px;
    height: 25px;
    vertical-align: top; }

.icon-mail,
.icon-email {
    color: $bizibaza_lightblue;
    font-size: 28px;
    height: 28px; }

.icon-profile_icon {
    color: $altgreydark;
    font-size: 32px;
    height: 32px; }

.icon-cart_icon {
    color: $bizibazagreen;
    font-size: 29px;
    height: 29px; }

.icon-star {
    font-size: 30px;
    height: 30px;
    color: $yellow; }

.icon-pay_icon {
    font-size: 26px;
    height: 26px;
    color: $bizibazagreen;

    [disabled] & {
        color: $disabledgray; } }

.icon-home {
    font-size: 25px;
    height: 25px;
    color: $bizibazagreen; }

.point-container {
    [class^='icon-'] {
        margin: auto;
        color: $bizibazagray; }
    .icon-pencil {
        font-size: 20px; }
    .icon-dollar-1 {
        font-size: 24px; }
    .icon-shop {
        font-size: 19px; }
    .icon-beer {
        font-size: 21px; }
    .icon-hashtag {
        font-size: 20px; } }

.sortShoppingList {
    .icon-sort-name-up {
        font-size: 25px;
        color: $airgrey; } }

.product-header__top {
    padding-right: 1rem !important;
    .button-watchlist {
        min-width: 44px;
        .icon-eye_filled {
            font-size: 28px; }
        .icon-eye_outlined {
            font-size: 24px; } } }

.expire-time {
    font-size: 20px;
    color: $airgrey;
    .icon-clock_icon_shopping_cart {
        margin-right: .2rem; } }

.analytics-details__buttons-block__message {
    font-size: 25px;
    color: $bizibaza_blue; }

.product-editor__add-event {
    .icon-plus-circle {
        margin-left: .2rem;
        font-size: 19px;
        color: $bizibazagreen; } }

.content_col_point {
    .icon-phone {
        font-size: 23px; }
    .icon-globe {
        font-size: 25px; }
    .icon-at {
        font-size: 25px; } }

.icon-location {
    font-size: 32px;
    color: $bizibazagray;

    .addr-editor__locate & {
        color: $bizibazagreen;
        font-size: 24px; } }

.icon-user-circle-o {
    font-size: 44px;
    color: $altgrey; }

.icon-crr_icon {
    margin-right: 0.2rem;
    font-size: 24px;
    color: $purple;

    .duties-list & {
        margin-top: 20px;
        margin-bottom: 20px; } }

.icon-floppy {
    font-size: 27px;
    color: $mineShaft; }

.icon-attention-alt-1 {
    font-size: 24px;
    color: $airgrey; }

.icon-location-address {
    font-size: 24px;
    color: $bizibazagreen; }

.abstract-list__product__open {
    & .icon-right-open {
        display: inline-block;
        vertical-align: top;
        font-size: 21px;
        width: 30px; } }
